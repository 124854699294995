var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('vuexy-logo'),_c('h2',{staticClass:"brand-text text-primary"},[_c('img',{attrs:{"src":require("@/assets/images/logo/logo-mercadohit.svg"),"width":"150"}})])],1),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"7"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"5"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Inicie a sua jornada de sucesso 🚀 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Cadastre-se agora ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.register($event)}}},[_c('b-form-group',{attrs:{"label":"Nome Completo","label-for":"usuarioNome"}},[_c('validation-provider',{attrs:{"name":"usuarioNome","vid":"usuarioNome","rules":"required|validarNomeSobrenome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"usuarioNome","name":"usuarioNome","state":errors.length > 0 ? false:null,"placeholder":"Nome e Sobrenome"},model:{value:(_vm.usuarioNome),callback:function ($$v) {_vm.usuarioNome=$$v},expression:"usuarioNome"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label":"Data Nascimento","label-for":"usuarioDtNascimento"}},[_c('validation-provider',{attrs:{"name":"usuarioDtNascimento","vid":"usuarioDtNascimento","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('the-mask',{staticClass:"form-control",attrs:{"id":"usuarioDtNascimento","state":errors.length > 0 ? false:null,"mask":['##/##/####'],"masked":false,"placeholder":"DD/MM/AAAA"},model:{value:(_vm.usuarioDtNascimento),callback:function ($$v) {_vm.usuarioDtNascimento=$$v},expression:"usuarioDtNascimento"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label":"CPF","label-for":"usuarioCpf"}},[_c('validation-provider',{attrs:{"name":"usuarioCpf","vid":"usuarioCpf","rules":"required|cpf"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('the-mask',{staticClass:"form-control",attrs:{"id":"usuarioCpf","state":errors.length > 0 ? false:null,"mask":['###.###.###-##'],"placeholder":"000.000.000-00"},model:{value:(_vm.usuarioCpf),callback:function ($$v) {_vm.usuarioCpf=$$v},expression:"usuarioCpf"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-form-group',{attrs:{"label":"WhatsApp","label-for":"usuarioCelular"}},[_c('validation-provider',{attrs:{"name":"usuarioCelular","vid":"usuarioCelular","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('the-mask',{staticClass:"form-control",attrs:{"id":"usuarioCelular","state":errors.length > 0 ? false:null,"mask":['(##) #####-####'],"placeholder":"(00) 00000-0000"},model:{value:(_vm.usuarioCelular),callback:function ($$v) {_vm.usuarioCelular=$$v},expression:"usuarioCelular"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"usuarioEmail"}},[_c('validation-provider',{attrs:{"name":"usuarioEmail","vid":"usuarioEmail","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"usuarioEmail","name":"usuarioEmail","state":errors.length > 0 ? false:null,"placeholder":"email@exemplo.com","autocomplete":"off"},model:{value:(_vm.usuarioEmail),callback:function ($$v) {_vm.usuarioEmail=$$v},expression:"usuarioEmail"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-for":"usuarioSenha","label":"Senha"}},[_c('validation-provider',{attrs:{"name":"usuarioSenha","vid":"usuarioSenha","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"usuarioSenha","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"usuarioSenha","placeholder":"············","autocomplete":"off"},model:{value:(_vm.usuarioSenha),callback:function ($$v) {_vm.usuarioSenha=$$v},expression:"usuarioSenha"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',[_c('b-form-group',{attrs:{"label-for":"usuarioSenhaConfirmacao","label":"Confirme Senha"}},[_c('validation-provider',{attrs:{"name":"usuarioSenhaConfirmacao","vid":"usuarioSenhaConfirmacao","rules":"required|confirmed:usuarioSenha"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"usuarioSenhaConfirmacao","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"usuarioSenhaConfirmacao","placeholder":"············"},model:{value:(_vm.usuarioSenhaConfirmacao),callback:function ($$v) {_vm.usuarioSenhaConfirmacao=$$v},expression:"usuarioSenhaConfirmacao"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-form-group',[_c('validation-provider',{attrs:{"name":"aceitoPolitica","vid":"aceitoPolitica","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-checkbox',{attrs:{"id":"register-privacy-policy","name":"aceitoPolitica","state":errors.length > 0 ? false : _vm.isChecked ? true : null},model:{value:(_vm.isChecked),callback:function ($$v) {_vm.isChecked=$$v},expression:"isChecked"}},[_vm._v(" Aceito os "),_c('b-link',{on:{"click":function($event){return _vm.abrirTermosUso()}}},[_vm._v("termos de uso ")]),_vm._v(" e "),_c('b-link',{on:{"click":function($event){return _vm.abrirPoliticaPrivacidade()}}},[_vm._v("política de privacidade")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid || _vm.respostaApi || !_vm.isChecked}},[_vm._v(" Finalizar "),(_vm.respostaApi)?_c('b-spinner',{attrs:{"small":"","label":"Carregando"}}):_vm._e()],1)],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Já tem uma conta?")]),_c('b-link',{attrs:{"to":{name:'autenticacao-login'}}},[_c('span',[_vm._v(" Acesse agora")])])],1)],1)],1)],1),_c('b-modal',{ref:"politicaPrivacidade",attrs:{"id":"politicaPrivacidade","title":"Termos de Política de Privacidade","size":"lg","scrollable":"","hide-footer":"","no-close-on-backdrop":""}},[_c('b-card-body',{staticClass:"p-2"},[_c('politica-privacidade')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }