<template>
    <div>
      <h1>Política de Privacidade MercadoHit</h1>
      <div class="AgreementsTemplate_text__vUNfp">
            <p style="text-align:justify;">PRIVACIDADE DA INFORMAÇÃO<br>&nbsp;</p>
            <p style="text-align:justify;"><strong>GETNINJAS ATIVIDADES DE INTERNET LTDA</strong> (“GetNinjas”), sociedade empresária limitada, inscrita no CNPJ/MF sob o nº. 14.127.813/0001-51, é uma pessoa jurídica de direito privado prestadora de serviços de anúncios online, realizados entre promitentes contratantes (“Contratante”) e promitentes Prestadores de serviço (“Prestadores”), por meio do aplicativo e do site GetNinjas (“Plataforma”).</p>
            <p style="text-align:justify;">Por intermédio desta Política de Privacidade (“Política de Privacidade”), GetNinjas esclarece aos usuários em geral, aos Contratantes e aos Prestadores (todos em conjunto denominados “Usuários”) as suas práticas no que diz respeito à privacidade das informações prestadas pelos Usuários, além de demonstrar o nosso comprometimento para resguardar a sua privacidade e proteger seus dados pessoais.</p>
            <p style="text-align:justify;">&nbsp;</p>
            <p style="text-align:justify;"><span style="color:black;">i.Todas as informações ou os dados pessoais prestados pelo Usuário de&nbsp;GetNinjas&nbsp;são armazenados em servidores ou meios magnéticos de alta segurança.</span></p>
            <p style="text-align:justify;">&nbsp;</p>
            <p style="text-align:justify;"><span style="color:black;">ii. GetNinjas&nbsp;tomará todas as medidas possíveis para manter a confidencialidade e a segurança descritas nesta cláusula, mas não será responsável por prejuízo que possa ser derivado da violação dessas medidas por parte de terceiros que utilizem de meios indevidos, fraudulentos ou ilegais para acessar as informações armazenadas nos servidores ou nos bancos de dados utilizados por GetNinjas.</span></p>
            <p style="text-align:justify;">&nbsp;</p>
            <p style="text-align:justify;"><span style="color:black;">iii. GetNinjas&nbsp;coleta os seguintes dados e informações pessoais de seus Usuários:&nbsp;</span></p>
            <p>&nbsp;</p>
            <p style="margin-left:40px;"><strong>(i) </strong>Nome completo;&nbsp;</p>
            <p style="margin-left:40px;"><strong>(ii)</strong> Número de telefone celular;&nbsp;</p>
            <p style="margin-left:40px;"><strong>(iii) </strong>E-mail;&nbsp;</p>
            <p style="margin-left:40px;"><strong>(iv)</strong> CEP;&nbsp;</p>
            <p style="margin-left:40px;"><strong>(v)</strong> Geolocalização com base no endereço IP;&nbsp;</p>
            <p style="margin-left:40px;">(<strong>vi) </strong>Endereço IP (Internet Protocol);&nbsp;</p>
            <p style="margin-left:108.0pt;text-align:justify;">&nbsp;</p>
            <p style="margin-left:2.0cm;text-align:justify;">&nbsp;</p>
            <p style="text-align:justify;"><span style="color:black;">iv. Essas&nbsp;informações&nbsp;são coletadas quando o Usuário as inseri na plataforma e são armazenadas e tratadas por GetNinjas para&nbsp;as seguintes finalidades:&nbsp;</span></p>
            <p style="margin-left:40px;text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(i) </strong>Manutenção cadastral necessária para o fornecimento dos serviços de anúncios online;</p>
            <p style="margin-left:40px;text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(ii)</strong> Identificação do Usuário na Plataforma e no Aplicativo;&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(iii) </strong>Viabilizar a utilização pelo Usuário da Plataforma e do Aplicativo;&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(iv) </strong>Localização prestadores de serviço próximos ao Usuário;</p>
            <p style="margin-left:40px;text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(v) </strong>Prevenção de fraudes e implementação de medidas de segurança da plataforma e do aplicativo;</p>
            <p style="margin-left:40px;text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(vi) </strong>Comunicação a respeito de pontos que possam ser de interesse do Usuário e que sejam relacionados aos serviços de anúncios da GetNinjas;</p>
            <p style="margin-left:40px;text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(vii)</strong> Prestação dos serviços de suporte e atendimento solicitados pelo Usuário;&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(viii) </strong>Realização de pesquisas de uso e interação da Plataforma e do Aplicativo para aprimoramento dos serviços de anúncio oferecidos por GetNinjas;<br>&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;">(<strong>ix) </strong>Geração de relatórios estatísticos a respeito do uso e interação dos Usuários;&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><span style="color:black;"><strong>(x) </strong>Promoção da GetNinjas para informar o Usuário sobre novidades, funcionalidades, conteúdos, notícias e demais eventos relevantes para a &nbsp;manutenção do relacionamento deste relacionamento. Tais comunicações são realizadas por meio de ferramentas automáticas de envio de mensagens criadas a partir de processos customizados baseados no histórico do Usuário;</span></p>
            <p style="margin-left:40px;text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(xi)</strong> Cumprir as obrigações legais e regulatórias aos quais GetNinjas está sujeito;&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(xii)</strong> Resguardar GetNinjas dos seus direitos e obrigações relacionados a Plataforma e&nbsp;<span style="color:black;">&nbsp;ao Aplicativo, conforme disposições da legislação brasileira; e</span></p>
            <p style="margin-left:40px;text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><span style="color:black;"><strong>(xiii)</strong> Colaborar e/ou cumprir ordem judicial ou requisição por autoridade administrativa.&nbsp;</span></p>
            <p style="text-align:justify;">&nbsp;</p>
            <p style="text-align:justify;"><span style="color:black;">v. Durante a navegação do Usuário na plataforma e no aplicativo, GetNinjas também coleta as seguintes informações e dados dos Usuários:&nbsp;</span></p>
            <p style="text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(i) </strong>Acesso e ações realizadas pelo Usuário na Plataforma e no Aplicativo;</p>
            <p style="margin-left:40px;text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(ii) </strong>Páginas e telas acessadas pelo Usuário na Plataforma e no Aplicativo;</p>
            <p style="margin-left:40px;text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(iii) </strong>Datas e horários de cada ação do Usuário na Plataforma e no Aplicativo;</p>
            <p style="margin-left:40px;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(iv)</strong> Informações sobre o dispositivo e o navegador utilizado pelo Usuário; e</p>
            <p style="margin-left:40px;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(v) </strong>Session e User ID, quando disponível.</p>
            <p style="margin-left:2.0cm;text-align:justify;">&nbsp;</p>
            <p style="text-align:justify;"><span style="color:black;">Os dados e informações descritos neste item são coletados por meio de cookies. Estes são arquivos que reúnem informações dos Usuários a partir de seu acesso na plataforma.&nbsp;</span></p>
            <p style="text-align:justify;">&nbsp;</p>
            <p style="text-align:justify;"><span style="color:black;"><strong>vi.&nbsp;Os dados e informações pessoais dos Usuários coletados poderão ser compartilhados com parceiros da GetNinjas quando necessário para as mesmas finalidades descritas acima</strong>.</span></p>
            <p style="text-align:justify;">&nbsp;</p>
            <p style="text-align:justify;"><span style="color:black;">vii. GetNinjas não tem acesso ao conteúdo das ligações ou comunicações entre os Usuários.</span></p>
            <p style="text-align:justify;">&nbsp;</p>
            <p style="text-align:justify;"><span style="color:black;">viii. GetNinjas, por motivos legais, manterá em seu banco de dados todas as informações coletadas dos Usuários que excluírem seus cadastros que forem exigidas por lei, tais como itens referentes a notas fiscais.</span></p>
            <p style="text-align:justify;">&nbsp;</p>
            <p style="text-align:justify;"><span style="color:black;">ix. O apelido e a senha de cada Usuário servem para garantir a privacidade e a sua segurança. GetNinjas recomenda a seus Usuários que não compartilhem essas informações com ninguém. GetNinjas não se responsabiliza por danos ou prejuízos causados ao Usuário pelo compartilhamento dessas informações.</span></p>
            <p style="text-align:justify;">&nbsp;</p>
            <p style="text-align:justify;"><span style="color:black;">x. Toda informação prestada pelos Usuários a GetNinjas é protegida por empresas especializadas.</span></p>
            <p style="text-align:justify;">&nbsp;</p>
            <p style="text-align:justify;"><span style="color:black;">xi. GetNinjas coleta e guarda todas as informações prestadas por seus usuários durante a utilização de sua plataforma, incluindo no momento do cadastramento, para uso próprio e de seus parceiros comerciais.</span></p>
            <p style="text-align:justify;">&nbsp;</p>
            <p style="text-align:justify;"><span style="color:black;">xii. GetNinjas prestará todas as informações requisitadas por órgãos públicos, desde que devidamente justificadas e compatíveis com a lei em vigor.</span></p>
            <p style="text-align:justify;">&nbsp;</p>
            <p style="text-align:justify;"><span style="color:black;">xiii. GetNinjas utiliza cookies e softwares de monitoramento de seus Usuários para prover a seus Usuários a melhor navegação possível, baseado em suas necessidades, e para pesquisas internas. Esses cookies não coletam informações pessoais, apenas informam preferências de uso e de navegação de cada Usuário, além de prover a GetNinjas estatísticas e dados para aprimorar seus serviços.</span></p>
            <p>&nbsp;</p>
            <p style="text-align:justify;"><span style="color:black;">xiv. No caso de suspeita ou de efetiva violação de dados pessoais dos Usuários, GetNinjas se compromete a notificar os Usuários afetados.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p style="text-align:justify;"><span style="color:black;">xv. No caso de mudanças ou alterações na presente Política Privacidade, GetNinjas se compromete a notificar os Usuários por meio de<strong>&nbsp;</strong>sua plataforma.&nbsp;</span></p>
            <p>&nbsp;</p>
            <p style="text-align:justify;"><span style="color:black;"><strong>xvi. De acordo com a&nbsp;</strong>Lei Geral de Proteção de Dados – Lei nº 13.709/18, o Usuário, poderá requerer:</span></p>
            <p style="text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(i) Acesso aos Dados Pessoais</strong>: garante ao Usuário receber uma cópia de todos os seus Dados em nossa base de dados.</p>
            <p style="margin-left:40px;text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(ii) Confirmação de Tratamento de Dados Pessoais:&nbsp;</strong>garante ao Usuário a confirmação de que estamos Tratando de seus Dados pessoais.</p>
            <p style="margin-left:40px;text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(iii) Revogação do consentimento:&nbsp;</strong>garante ao Usuário a possibilidade de revogar o consentimento, anteriormente demonstrado.<strong>&nbsp;</strong></p>
            <p style="margin-left:40px;text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(iv) Da portabilidade</strong>: possibilidade de fornecer ao Usuário ou terceiros que forem indicados pelo Usuário, os Dados Pessoais em formato estruturado, &nbsp; &nbsp; &nbsp;desde que respeitado o segredo comercial de nossos produtos;</p>
            <p style="margin-left:40px;text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(v) ação</strong>: possibilita a solicitação, a qualquer momento, da retificação de quaisquer Dados Pessoais, caso encontrem-se incompletos, inexatos ou &nbsp; desatualizados, que estejam aos cuidados de GetNinjas;</p>
            <p style="margin-left:40px;text-align:justify;">&nbsp;</p>
            <p style="margin-left:40px;text-align:justify;"><strong>(vi) Da exclusão</strong>: possibilita a solicitação da exclusão de seus Dados Pessoais, mesmo que o Usuário tenha dado prévio consentimento. Após a &nbsp; equisição, todos os dados que não forem relevantes para a prestação de nossos serviços (como, por exemplo, Dados que são necessários para &nbsp; cumprimento de obrigação legal), serão excluídos.</p>
            <p style="text-align:justify;">&nbsp;</p>
            <p style="text-align:justify;">A solicitação do exercício dos direitos dos titulares de dados deverá ser requerida por meio dos seguintes canais: chat online na &nbsp; página&nbsp;https://www.GetNinjas.com.br/ajuda/ &nbsp;ou pelo e-mail&nbsp;<a href="mailto:privacidade@getninjas.com.br"><strong>privacidade@getninjas.com.br</strong></a><strong> </strong>e&nbsp;nas condições estabelecidas pela Lei Geral de Proteção de Dados, de modo que GetNinjas se comprometerá a desprender dos melhores esforços para atender os pedidos quando cabíveis.&nbsp;</p>
        </div>
        
    </div>
  </template>
  
  <script>
  import {
    BCard, BCardBody, BForm, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, BTabs, BTab, BImg, BRow, BCol, BAvatar,
  } from 'bootstrap-vue'
  
  export default {
    components: {
      BForm,
      BCard,
      BRow,
      BCol,
      BAvatar,
      BCardBody,
      BInputGroup,
      BFormInput,
      BCardText,
      BInputGroupPrepend,
      BTabs,
      BTab,
      BImg,
    },
    data() {
      return {
        
      }
    },
    methods: {
      
    },
  }
  </script>